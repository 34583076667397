<template>
  <div
    class="cod-container"
    @click="goToCODPage()"
  >
    <div class="cod-container__left">
      <img
        class="cod-container__left__img"
        :src="isDfod ? '/images/tracking/dfod.svg' : (isCod && !isDfod ? '/images/tracking/cod.svg' : '/images/tracking/dfod.svg')"
        alt="icon-cod-dfod"
      >
      <div class="cod-container__left__text">
        <div v-show="isDfod">
          COD Ongkir
        </div>
        <div v-show="isCod && !isDfod">
          COD Ongkir & Barang
        </div>
      </div>
    </div>
    <div class="cod-container__right">
      <div class="cod-container__right__text">
        <div>Apa itu COD?</div>
      </div>
      <img
        class="cod-container__right__img"
        src="/images/tracking/chevron.svg"
        alt="icon-chevron"
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "CODInfo",
  props: {
    isCod: { type: Boolean },
    isDfod: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    goToCODPage() {
      this.$router.push("/cod");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/style";

.cod-container {
  cursor: pointer;
  width: 37.5vw;
  background-color: $color-gray-pensive;
  border-radius: 8px;
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 24px;

  @include for-size(mobile) {
    padding: 8px 12px;
    width: 100%;
  }
  
  @include for-size(tablet-portrait) {
    width: 100%;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 12px;

    &__img {
      display: flex;
      align-items: center;
    }

    &__text {
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      color: $color-gray-shades-6;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 6px;
    min-width: 105px;

    &__text {
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: $color-base;
    }

    &__img {
      display: flex;
      align-items: center;
    }
  }
}
</style>
