<template>
  <div id="coat-result-track">
    <div
      class="result-track"
      :style="!showExpand && isWarningStateTrack ? 'justify-content: center;' : ''"
    >
      <div
        v-if="isLoading"
        class="wrapper-skeleton"
        :style="totalSearchShipmentId > 1 ? 'width: 100%;': ''"
      >
        <div
          v-for="data in totalSearchShipmentId"
          :key="'box-'+ data"
        >
          <div class="track-number">
            <div class="skeleton-box skeleton-box-track" />
            <div class="skeleton-box skeleton-box-dp" />
          </div>
        </div>
      </div>
      <!-- START condition IF server error -->
      <div
        v-else-if="!isLoading && errorTrack !== ''"
        style="text-align: center;"
      >
        <WarningState status="ERROR" />
      </div>
      <!-- END condition IF server error  -->
      <div
        v-for="(dataResult, idx) in listTrack"
        v-else
        :id="defaultIDs.trackList + idx"
        :key="'data-track'+ idx"
        :class="{'result-track__item': listTrack.length > 1, 'result-track__item-width': isTrackJustOneData() && !dataResult.is_from_tokopedia}"
      >
        <!-- START condition IF stt/shipment id in-process, not available, cancel -->
        <div
          v-if="showExpand"
          class="track-number"
          :style="isTrackJustOneData() ? 'margin: auto;': ''"
          @click="toggle(idx)"
        >
          <div> Shipment ID <strong>{{ dataResult.q }}</strong></div>
          <div class="track-number__dropdown-icon">
            <img
              :id="defaultIDs.dropDown+idx"
              src="/images/tracking/drop-down.svg"
              alt="logo_dropdown.svg"
              :style="totalSearchShipmentId === 1 ? 'transform: rotate(-180deg);' : ''"
            >
          </div>
        </div>
        <div
          v-if="inWarningState(dataResult).isWarning"
          :id="defaultIDs.result + idx"
          class="track-state"
          :style="isLastResultTrackBox(idx, listTrack, true)"
        >
          <WarningState :status="inWarningState(dataResult).status" />
        </div>
        <!-- END condition IF stt/shipment id in-process, not available, cancel -->
        <!-- START condition IF has histories -->
        <div
          v-else
          :id="defaultIDs.result + idx"
          class="animation-track"
          :style="showExpand ? isLastResultTrackBox(idx, listTrack, true) : ''"
        >
          <CODInfo
            v-show="dataResult.is_cod || dataResult.is_dfod"
            :is-cod="dataResult.is_cod"
            :is-dfod="dataResult.is_dfod"
          />
          <EstimationInfo
            v-if="!dataResult.is_cnx"
            :etd="dataResult.estimation_date"
            :status="dataResult.current_status"
            :class="dataResult.is_cod || dataResult.is_dfod ? 'adjust-margin-top' : ''"
          />
          <ResultTrackInfo
            :data-histories="dataResult.histories"
            :data-attachment="dataResult.attachment"
            :is-from-tokopedia="dataResult.is_from_tokopedia"
            :is-show-modal-download="dataResult.is_show_download_app"
            @event-tokped="openTokopedia"
          />
        </div>
        <!-- END condition IF has histories -->
      </div>
      <!-- START modal download app if POD more than 20 Days -->
      <ModalDownloadApp
        v-if="showModalDownloadAPP || SHOW_ALERT"
        @close="onClose"
      />
      <!-- END modal download app if POD more than 20 Days -->
    </div>
    <EventAndTipsSection />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ResultTrackInfo from "./ResultTrackInfo";
import WarningState from "./WarningState";
import ModalDownloadApp from "./ModalDownloadApp";
import mixinMobile from "@/misc/mixinMobile";
import trackUseCase from "../app/usecase/track";
import EstimationInfo from "./EstimationInfo.vue";
import EventAndTipsSection from "./EventAndTipsSection.vue";
import CODInfo from "./CODInfo.vue";

export default {
  name: "ResultTrack",
  components: { ModalDownloadApp, ResultTrackInfo, WarningState, EstimationInfo, EventAndTipsSection, CODInfo },
  mixins: [mixinMobile],
  props: {
    /** @type {TrackResultMapper[]} */
    listTrack: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    errorTrack: {
      type: String,
      default: ""
    },
    totalSearchShipmentId: {
      type: Number,
      default: 1
    },
    // use false on account member for track result of stt member
    showExpand: {
      type: Boolean,
      default: true,
    },
    // use on account member for track result of stt member if it has status 'dibatalkan'
    isCancel: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      actionToggle: false,
      showModalDownloadAPP: false,
      isWarningStateTrack: false,
      defaultIDs: {
        trackList: "result-track__item-",
        result: "result-",
        dropDown: "icon-dropdown-"
      },
    }
  },
  computed: {
    ...mapGetters(["SHOW_ALERT"])
  },
  methods: {
    isTrackJustOneData() {
      return this.listTrack.length === 1
    },
    /**
     *
     * @param idName {string}
     * @return {Element}
     */
    domQueryID(idName) {
      return document.querySelector("#" + idName)
    },
    /** @param index {number} */
    toggle(index) {
      const showModalDownloadApp = this.listTrack[index].is_show_download_app
      const foundTokopediaSTT = this.listTrack[index].is_from_tokopedia
      const el = this.domQueryID(this.defaultIDs.result + index)
      const iconDropDown = this.domQueryID(this.defaultIDs.dropDown + index)
      if(el) {
        if (el.style.display === "none" || !el.style.display) {
          if (typeof showModalDownloadApp == "boolean" && (showModalDownloadApp || foundTokopediaSTT)) {
          this.showModalDownloadAPP = true
        }
          el.style.display = "block"
          iconDropDown.style.transform = "rotate(-180deg)"
      } else {
          el.style.display = "none"
          iconDropDown.style.transform = "rotate(0deg)"
        }
      }
    },
    /**
     *
     * @param dataResult {TrackResultMapper}
     * @return {{isWarning: boolean, status: (WARNING_STATE|String)}}
     */
    inWarningState(dataResult) {
      if (this.isCancel) {
        dataResult.is_cnx = true
      }
      this.isWarningStateTrack = trackUseCase.setWarningState(dataResult).isWarning
      return trackUseCase.setWarningState(dataResult)
    },
    /**
     *
     * @param idx {number}
     * @param listTrack {TrackResultMapper[]}
     * @return {object}
     */
    isLastResultTrackBox(idx, listTrack, marginAffected) {
      const style = {display: "none"}
      if (idx % 2 === 0 && (idx + 1) === listTrack.length && !this.isMobileSize && marginAffected) {
        let margin = "10% 0"
        if (listTrack.length % 2 === 1 && listTrack.length === idx + 1) {
          margin = "5% 0"
        }
        style.margin = margin
      }

      if (this.totalSearchShipmentId === 1) {
        style.display = "block"
      }

      return style
    },
    /** @param showModal {boolean} */
    onClose() {
      this.$store.dispatch("closeModalAlert") // in store/module/tracking.js
      this.showModalDownloadAPP = false
    },
    openTokopedia() {
      const shipmentTokopedia = []
      this.listTrack.forEach(v => {
        if (v.is_from_tokopedia && v.is_show_download_app) {
          shipmentTokopedia.push(v.q)
        }
      })
      window.open(`https://www.tokopedia.com/kurir-rekomendasi?awb=${shipmentTokopedia.join(",")}`)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style";
@import "../../../assets/css/flexbox";
@import "../../../assets/css/spacing";

$padding-mobile: 0% 5% 5% 5%;
$transition: all ease-in-out .3s;

.result-track {
  padding: 0 0 1rem 0;
  justify-content: center;
  display: flex;
  align-content: center;
  flex-wrap: wrap;

  > div {
    margin-bottom: 2%;
  }

  &__item {
    width: 35vw;
    display: flex;
    flex-direction: column;
    flex: 1 35%;
    padding-right: 6%;
    padding-left: 6%;

    @include for-size(xmobile) {
      width: 100vw;
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      flex: auto;
      padding: 5% 0;
      margin-bottom: 10%;
    }

    @include for-size(mobile) {
      width: 100vw;
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      flex: auto;
      padding: 5% 0;
      margin-bottom: 10%;
    }
  }

  &__item-width {
    width: 37.5vw;

    @include for-size(mobile) {
      width: 100%;
    }
  }

  @include for-size(xmobile) {
    width: 100%;
    padding: $padding-mobile;
  }

  @include for-size(mobile) {
    width: 100%;
    padding: $padding-mobile;
  }
}

.track-number {
  cursor: pointer;
  width: 37.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 1px 4px rgba(26, 20, 33, 0.15);
  border-radius: 5px;
  padding: 14px 24px !important;

  @include for-size(mobile) {
    width: 100%;
    padding: 21px 15px 18px 15px;
    font-size: 14px;
    line-height: 21px;
  }

  @include for-size(tablet-portrait) {
    width: 100%;
  }

  &__dropdown-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.animation-track {
  transition: $transition;
  margin: 12px 0 5% !important;

  @include for-size(mobile) {
    margin: 20px 0 10% !important;
  }  
  
  @include for-size(tablet-portrait) {
    margin: 20px 0 10% !important;
  }
}

.track-state {
  width: 35vw;
  display: none;
  text-align: center;
  margin: 10% 0;

  @include for-size(mobile) {
    width: 100%;
  }

  @include for-size(tablet-portrait) {
    width: 100%;
  }
}

.track-number-title {
  margin-bottom: 10px;
  margin-left: 24px;
  color: #78787A;
}

.wrapper-skeleton {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 6%;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  @include for-size(mobile) {
    padding: 0 0;
  }

  > div:nth-child(odd) {
    margin-right: 2%;
  }

  > * {
    margin-bottom: 5%;

    @include for-size(mobile) {
      margin: 7%;
    }
  }
}

.skeleton-box-track {
  width: 12rem;
  height: 24px;
  border-radius: 5px;

  @include for-size(mobile) {
    margin-right: 2%;
  }

  @include for-size(tablet-portrait) {
    width: 20rem;
    margin-right: 8%;
  }
}

.skeleton-box-dp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.adjust-margin-top {
  margin-top: -12px !important;

  @include for-size(mobile) {
    margin-top: -4px !important;
  }
  
  @include for-size(tablet-portrait) {
    margin-top: -4px !important;
  }
}
</style>
