<template>
  <div>
    <ImageLazy
      :src="'/images/tracking/' + trackState[status].src"
      alt="status_logo.svg"
      width="150px"
      height="150px"
    />
    <p id="track-warning-title">
      {{ trackState[status].title }}
    </p>
  </div>
</template>

<script>
import {WARNING_STATE} from "../app/enum/status";
import ImageLazy from "@/components/ImageLazy/index.vue";

export default {
  name: "WarningState",
  components: { ImageLazy },
  props: {
    // status related to trackState keys
    status: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      trackState: {
        [WARNING_STATE.EMPTY]: {
          src: "track_unavailable.svg",
          title: "Paket Anda Tidak Ditemukan\nPastikan STT/Nomor Resi yang Dimasukkan Sudah Benar"
        },
        [WARNING_STATE.CANCEL]: {
          src: "track_canceled.svg",
          title: "Paket Anda Telah Dibatalkan"
        },
        [WARNING_STATE.PROCESS]: {
          src: "track_in-process.svg",
          title: "Paket Anda Sedang Diproses"
        },
        [WARNING_STATE.ERROR]: {
          src: "track_unavailable.svg",
          title: "Maaf, ada kesalahan di server kami"
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/css/style";

#track-warning-title {
  white-space: pre-wrap;
  margin: 2% auto 0 auto;
  @include for-size(mobile) {
    width: 83vw;
  }
}
</style>
