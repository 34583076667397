<template>
  <div>
    <div id="base-track">
      <Banner />
    </div>
    <div class="wrapper-banner">
      <input-track @track="goingTrack" />
    </div>
    <div id="track-result-base-title">
      <div
        v-if="isSearchSTT && !IS_LOADING_TRACKING && RESULT_TRACK_ERROR === ''"
        id="track-result-base-title__text"
      >
        <p>Status Pengiriman Anda</p>
        <p>Status sesuai dengan nomor resi atau STT</p>
        <p> Klik shipment ID untuk melihat detailnya</p>
      </div>
    </div>
    <ResultTrack
      v-if="isSearchSTT"
      :list-track="RESULT_TRACK"
      :is-loading="IS_LOADING_TRACKING"
      :error-track="RESULT_TRACK_ERROR"
      :total-search-shipment-id="totalSearchSTT"
    />
  </div>
</template>

<script>
import './app/type/index.js';
import {mapGetters} from 'vuex';
import ResultTrack from './components/ResultTrack';
import InputTrack from './components/InputTrack';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import Banner from './components/Banner.vue';

export default {
  name: 'Track',
  components: {
    InputTrack,
    ResultTrack,
    Banner,
  },
  mixins: [mixinMetaInfo],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url
    });
  },
  data() {
    return {
      searchMetaInfo: '_track_stt', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      altBanner: 'lionparcel-cek-resi-banner.svg',
      isSearchSTT: false,
      totalSearchSTT: 1,
    }
  },
  computed: {
    ...mapGetters([
      'IS_LOADING_TRACKING',
      'RESULT_TRACK',
      'RESULT_TRACK_ERROR'
    ]),
  },
  created() {
    /** @type {string | (string | null)[]} */
    const q = this.$route.query['q']
    if (q && q !== '') {
      this.isSearchSTT = true
      this.totalSearchSTT = q.split(',').length
    }
  },
  methods: {
    /** @param totalSearch {number} */
    goingTrack(totalSearch) {
      this.isSearchSTT = totalSearch >= 1
      this.totalSearchSTT = totalSearch
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../src/assets/css/style';

::v-deep .breadcrumb {
  z-index: 2;
  position: absolute;
  top: 14%;
  left: -20px;

  @include for-size(big-desktop) {
    top: 16%;
  }
}

#base-track {
  z-index: 1;
}

.img-desktop {
  display: block;

  @include for-size(xmobile) {
    display: none;
  }

  @include for-size(mobile) {
    display: none;
  }
}

.img-mobile {
  display: none;
  padding-top: 3%;

  @include for-size(xmobile) {
    display: block;
  }

  @include for-size(mobile) {
    display: block;
  }
}

.wrapper-banner {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: -3.5%;
  justify-content: center;
  align-items: center;
  align-content: center;

  & > div {
    margin-bottom: 2%;
  }

  @include for-size(mobile) {
    margin-top: -10%;
  }

  @include for-size(tablet-portrait) {
    margin-top: -3%;
  }
}

#track-result-base-title {
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  text-align: center;

  @include for-size(mobile) {
    padding-top: 5%;
  }

  @include for-size(tablet-portrait) {
    padding-top: 3%;
  }

  &__text {
    p:first-child {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #4D4D4D;
    }

    p:nth-of-type(2) {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #78787A;

      @include for-size(mobile) {
        margin-bottom: 20px;
      }
    }

    p:last-child {
      display: block;
      margin-top: 32px;
      margin-bottom: 24px;
      color: #A7A8AA;

      @include for-size(mobile) {
        display: none;
      }
    }
  }
}
</style>
