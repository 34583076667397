<template>
  <div id="base-input-track">
    <div class="lp-title">
      <h1>
        {{ title }}
      </h1>
      <div
        v-if="listInput.length > 1"
        class="lp-title__cancel"
        @click="cancelTrack()"
      >
        Batal
      </div>
    </div>
    <div class="form-input-track">
      <div class="lp-label">
        <h2>{{ input.label }}</h2>
      </div>
      <div class="form-input-track__data mt-1">
        <BaseInput
          v-for="(track, idx) in listInput"
          :key="'track-' + idx"
          v-model.trim="track.track"
          :name="'track-' + idx"
          :show-del-button="listInput.length > 1"
          :placeholder="input.placeHolder"
          :class-name="classNameInput"
          custom-class="mr-1 mb-2"
          @input="handleInput(idx)"
        >
          <template #icon>
            <img
              v-if="listInput.length > 1"
              :src="'/images/icon-close.svg'"
              alt="del-icon.svg"
              class="input-img"
              @click="eventDeleteBox(idx)"
            >
          </template>
        </BaseInput>
        <div class="wrapper-card-feature">
          <div
            v-show="listInput.length < 5"
            id="Tambah_1st"
            class="card-feature clickable"
            @click="addMoreBox()"
          >
            <div class="mr-1 pointer-events-none">
              <img
                :src="'/images/icon-add.svg'"
                alt="icon-add.svg"
              >
            </div>
            <div class="mb-2 pointer-events-none">
              Tambah
            </div>
          </div>
        </div>
        <div class="btn-track">
          <Button
            text="Lacak"
            type-class="red"
            @click="goingToTrack()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/new-input/Input.vue';
import Button from '@/components/new-button/Button';
import mixinMobile from '@/misc/mixinMobile';

export default {
  name: 'InputTrack',
  components: { BaseInput, Button },
  mixins: [mixinMobile],
  data() {
    return {
      title: 'Lacak Pengiriman',
      input: {
        label: 'Masukan Nomor Pengiriman',
        placeHolder: 'Masukkan nomor resi atau STT',
      },
      listInput: [{ track: '' }],
      listSTT: [],
      styleCard: {
        width: '100%',
      },
    };
  },
  computed: {
    classNameInputMoreBox() {
      return this.isMobileSize ? 'input-xl' : 'input-sm';
    },
    classNameInput() {
      if (this.listInput.length > 1) {
        return this.classNameInputMoreBox;
      }
      return 'input-10xl';
    },
  },
  mounted() {
    /** @type {string | (string | null)[]} */
    const { q } = this.$route.query;
    if (q && q !== '') {
      this.setQueryParams();
      this.goingToTrack();
    }
  },
  methods: {
    suffix_of(i) {
      var j = i % 10,
      k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
    cancelTrack() {
      this.listInput = [{ track: '' }];
      document.querySelector('[id^="Tambah_"]').id = 'Tambah_1st';
    },
    addMoreBox() {
      this.styleCard.width = 'auto';
      this.listInput.push({ track: '' });
      document.querySelector('[id^="Tambah_"]').id = 'Tambah_' + this.suffix_of(this.listInput.length);
    },
    eventDeleteBox(index) {
      this.listInput.splice(index, 1);
      this.listSTT.splice(index, 1);
      if (this.listInput.length === 1) {
        this.styleCard.width = '100%';
      }
      document.querySelector('[id^="Tambah_"]').id = 'Tambah_' + this.suffix_of(this.listInput.length);
    },
    setEmptyValueForm() {
      this.listInput = this.listInput.filter(v => v.track !== ''); // ignore empty value
      this.listInput = [...new Set(this.listInput.map(JSON.stringify))].map(JSON.parse); // ignore duplicate

      const newStt = [];
      for (const stt of this.listInput) {
        newStt.push(stt.track);
      }
      this.listSTT = newStt;
      this.replaceQueryParamRoute();
    },
    goingToTrack() {
      const isAllEmpty = this.listInput.every(v => v.track === '');
      if (!isAllEmpty) {
        this.setEmptyValueForm();
        window.scroll(0, 400);
        const payload = {
          tracks: this.listSTT,
        };
        this.$store.dispatch('initTrackShipment', payload);
        this.$emit('track', this.listSTT.length);
      } else {
        this.listInput = [{ track: '' }];
        this.$emit('track', 0);
        window.history.replaceState(null, null, '?q=');
      }
    },
    handleInput(index) {
      this.listInput[index].track = this.listInput[index].track.toUpperCase();
    },
    setQueryParams() {
      const { q } = this.$route.query;
      const newListInput = [];
      if (q !== '' && q) {
        const listSTTs = q.split(',');
        for (const listSTT of listSTTs) {
          if (newListInput.length < 5) {
            newListInput.push({ track: listSTT });
            this.listSTT.push(listSTT);
          }
        }
        this.listInput = newListInput;
        this.styleCard.width = 'auto';
        this.replaceQueryParamRoute();
      }
    },
    replaceQueryParamRoute() {
      window.history.replaceState(null, null, `?q=${this.listSTT.join(',')}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/spacing";
@import "../../../assets/css/style";

%f-column {
  display: flex;
  flex-direction: column;
}

#base-input-track {
  z-index: 1;
  position: relative;
  border-radius: 10px;
  max-width: 99%;
  width: 88%;
  background: #FFFFFF;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  padding: 20px 40px 31px 40px;
  @extend %f-column;
  @include for-size(mobile) {
    padding: 20px 20px 31px 22px;
  }

  > div {
    margin-bottom: .5%;
  }

  .lp-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #4D4D4D;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1 {
      font-weight: 600;
    }

    @include for-size(mobile) {
      font-size: 14px;
    }

    &__cancel {
      color: #D11F40;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: all ease-in-out 0.3s;
      -moz-transition: all ease-in-out 0.3s;
      -webkit-transition: all ease-in-out 0.3s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .lp-label {
    margin-left: 8px;
    font-size: 12px;
    line-height: 15px;
    color: #78787A;
    @include for-size(mobile) {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
}

.form-input-track {
  @extend %f-column;
  @include for-size(tablet-portrait) {
    max-width: 78%;
  }

  > div {
    margin-bottom: .5%;
    @include for-size(mobile) {
      margin-bottom: 2%;
    }
    @include for-size(xmobile) {
      margin-bottom: 2%;
    }
  }

  &__data {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @media (max-width: 1399px) {
      flex-wrap: wrap;
    }

    @include for-size(xmobile) {
      flex-direction: column;
      .btn-track {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: stretch;
      }
    }

    @include for-size(tablet-portrait) {
      .btn-track {
        margin-left: 0;
      }
    }
  }
}

.wrapper-card-feature {
  display: flex;
  width: 100%;
  margin: auto;

  .card-feature {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-left: .35rem;

    @include for-size(xmobile) {
      justify-content: flex-start;
    }

    > div {
      img {
        cursor: pointer;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}
</style>
