<template>
  <modal @close="$emit('close')">
    <slot>
      <div class="modal-body-wrapper">
        <div>
          Pengiriman Paket Anda Telah Selesai
        </div>
        <div>
          Pengiriman paket anda telah selesai dan diterima. Silahkan melihat detail lacak pengiriman melalui aplikasi
          Lion Parcel
        </div>
        <div class="do-flex">
          <img
            :src="'/images/playstore.png'"
            alt="playstore lion parcel.png"
            @click="openLink('android')"
          >
          <div class="mr-2 ml-2" />
          <img
            :src="'/images/appstore.png'"
            alt="appstore lion parcel.png"
            @click="openLink('ios')"
          >
        </div>
      </div>
    </slot>
  </modal>
</template>

<script>
import Modal from "@/components/new-modal/Modal";

export default {
  name: "ModalDownloadApp",
  components: {Modal},
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModalDownloadAPP: false,
      link: {
        android: "https://play.google.com/store/apps/details?id=com.lionparcel.services.consumer&hl=in",
        ios: "https://apps.apple.com/id/app/lion-parcel/id1455977134",
      },
    }
  },
  methods: {
    openLink(os) {
      window.open(this.link[os], "_blank");
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";
@import "src/assets/css/flexbox.scss";
@import "src/assets/css/spacing.scss";

$transition: all ease-in-out .3s;

.modal-body-wrapper {
  width: 30vw;
  @include do-flex(column, center, 0 0 2% 0);
  align-items: center;

  @include for-size(mobile) {
    width: 80vw;
  }

  > * {
    margin-right: 0;
  }

  div:first-child {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #4D4D4D;
  }

  div:nth-child(2) {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #4D4D4D;
    width: 22vw;
    @include for-size(mobile) {
      width: auto;
    }
  }

  div:last-child {
    width: 20%;
    justify-content: center;
    display: inline-flex;
    margin-top: 2%;
    cursor: pointer;
    @include for-size(mobile) {
      margin-top: 1rem;
      width: 36%;
    }

    img {
      transition: $transition;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

::v-deep .modal-lp {

  &__content {
    width: 35vw;

    @include for-size(mobile) {
      width: 100%;
    }

    @include for-size(tablet-portrait) {
      width: 100%;
    }

    &__body {
      overflow: hidden !important;

      .modal-body-wrapper {
        @include for-size(tablet-portrait) {
          width: 100%;
        }
      }
    }

  }
}
</style>
