<template>
  <div
    v-if="isShowEtd()"
    class="estimation-container">
    <img
      class="estimation-container__alert"
      src="/images/ics_f_info_circle.svg"
      alt="Info Circle Icon"
    >
    <div class="estimation-container__text">
      Estimasi Paket Anda akan tiba sekitar tanggal {{FormatedEtd()}}
    </div>
  </div>
</template>
<script>
import {TimeMapper} from "@/misc/timeMapper";
export default {
  name: 'EstimationInfo',
  data() {
    return {
      isEstimationPassed: false,
      isEmptyEstimationDate: false,
      excludedStatus: ['cnx','dex','scrap','pod','sti-dest']
    }
  },
  props: {
    etd: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },
  methods: {
    FormatedEtd() {
      if (this.etd === '-') {
        this.isEmptyEstimationDate = true
        return
      }
      const source = this.etd;
      const index = source.indexOf(" ");  // Gets the first index where a space occours
      const first = TimeMapper.SetFormatDate(source.substr(0, index), true , [" ", " "], false); // Gets the first part (DD-MONTH-YYYY)
      const last = TimeMapper.SetFormatDate(source.substr(index + 3), true , [" ", " "], false);  // Gets the last part (DD-MONTH-YYYY)
      this.isEstimationPassed = new Date(source.substr(index + 3)).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)
      const final = first + " - " + last
      return final
    },
    isShowEtd() {
      return !(this.excludedStatus.includes(this.status.toLowerCase()) ||
          this.isEmptyEstimationDate ||
          this.isEstimationPassed);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style";

.estimation-container {
  @include for-size(mobile) {
    width: 100%;
  }

  @include for-size(tablet-portrait) {
    width: 100%;
  }

  width: 37.5vw;
  border: 1px dashed $color-white-dark;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: $color-base-text;
  }
}
</style>
