import { WARNING_STATE } from '../enum/status';

class TrackUseCase {
  /**
     *
     * @param dataTrack {TrackResultMapper}
     * @return {{isWarning: boolean, status: (WARNING_STATE|String)}}
     */
  setWarningState(dataTrack) {
    if (!dataTrack.histories && dataTrack.is_stt && dataTrack.is_exist) {
      return { isWarning: true, status: WARNING_STATE.PROCESS };
    }

    if (!dataTrack.is_stt && !dataTrack.is_exist) {
      return { isWarning: true, status: WARNING_STATE.EMPTY };
    }

    return { isWarning: false, status: WARNING_STATE.EMPTY };
  }
}

export default new TrackUseCase();
