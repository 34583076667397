export const STATUS = {
  'POD': {src: 'pod'},
  'DEL': {src: 'with-courier'},
  'STI-DEST': {src: 'at-destination'},
  'STI DEST-SC': {src: 'at-destination'},
  'C-PLACE': {src: 'cargo'},
  'STI': {src: 'at-destination'},
  'STI-SC': {src: 'at-destination'},
  'PUP': {src: 'warehouse-destination'},
  'BKD': {src: 'new-on-process'},
  'CRRDON': {src: 'new-on-process'},
  'CRRPIC': {src: 'with-courier'},
  'CRROTW': {src: 'with-courier'},
  'CRRQUE': {src: 'with-courier'},
  'CRRNRS': {src: 'search'},
  'CRRNFD': {src: 'search'},
  'CRRSRC': {src: 'search'},
  'SHPCRT': {src: 'on-process'},
  'DRPCRT': {src: 'on-process'},
  'DRPIDM': {src: 'on-process'},
  'CSTCNC': {src: 'x'},
  'ADPCNC': {src: 'x'},
  'PICKUP_TRUCKING': {src: 'truck'},
  'C-TRUCK': {src: 'truck'},
  'TRANSIT': {src: 'transit'},
  'C-TRAIN': {src: 'train'},
  'CODREJ': {src: 'failed'},
  'HAL': {src: 'at-destination'},
  'CNX': {src: 'x'},
  'CARGO TRUCK' : {src: 'truck'},
  'DEX' : {src: 'x'},
  'CARGO PLANE': {src: 'cargo'},
  'CARGO TRAIN': {src: 'train'},
  'RTS': {src: 'failed'},
  'CARGO SHIP': {src: 'ship'},
  'RTSHQ': {src: 'return'},
  'REROUTE': {src: 'reroute'},
  'MISBOOKING': {src: 'misbooking'},
  'OCC': {src: 'occ'},
  'INT-STI': {src: 'new-on-process'},
  'OCC-HAL': {src: 'at-destination'},
  'OCC-EXP': {src: 'checking'},
  'OCC-IMP': {src: 'checked'},
}
