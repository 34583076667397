<template>
  <div
    v-if="!isEmpty"
    id="base-event-tips"
  >
    <div class="event-tips-section">
      <div class="event-tips-section__item">
        <div class="event-tips-section__title">
          Beragam Artikel Seru Disini!
        </div>
        <router-link
          class="event-tips-section__others"
          to="/info-seller"
        >
          <span style="margin: auto;">Lihat Semua</span>
          <img
            src="/images/chevron_right_red.svg"
            alt="icon chevron lion parcel"
            width="24"
            height="24"
          >
        </router-link>
      </div>
      <div v-if="!isMobileSize">
        <div
          v-if="!isLoadingFetchData"
          class="animation-fade-in"
        >
          <Carousel
            slider-dot-position="center"
            :name="carouselName"
            :shimmer="false"
            :size="listChunk.length"
            :total-all-data="listData.length"
            :is-absolute="true"
            :show-dot="false"
            margin-slider="65px -35px 0px -35px"
            color-slider="redWithRound"
          >
            <template #item>
              <div
                v-for="(slide, index) in listChunk"
                :id="`${carouselName}-${index+1}`"
                :key="'item-key-' +index"
                class="slide-number event-tips-section-carousel__wrapper-item"
              >
                <router-link
                  v-for="(val, j) in slide"
                  :id="`id-${carouselName}-${j}`"
                  :key="`key-${index}-${j}`"
                  class="slide-item event-tips-section-carousel__wrapper-item__child"
                  :to="`/info-${val.article_info.toLowerCase()}/${val.corporate_action_slug}`"
                >
                  <div
                    class="event-tips-section-carousel__wrapper-item__child__image"
                    style="cursor: pointer;"
                  >
                    <ImageLazy
                      :id="`corporation-photo-${j}`"
                      :src="val.attachment_corporate_action_sub_photo_url"
                      :alt="val.alt_sub_photo"
                    />
                    <div class="event-tips-section-carousel__wrapper-item__child__image__desc">
                      {{ val.corporate_action_title }}
                    </div>
                    <div class="event-tips-section-carousel__wrapper-item__child__image__date">
                      {{ setTime(val.active_date) }}
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
          </Carousel>
        </div>
        <div
          v-else
          style="text-align: center;"
        >
          <div
            v-for="sk in defaultSize"
            :key="sk"
            class="skeleton-box event-tips-section-carousel__wrapper-item__child__image"
            style="height: 32vh; width: 20vw; margin-right: 10px; border-radius: 8px; max-width: 280px; max-height: 185px;"
          />
        </div>
      </div>
      <div
        v-else
        class="event-tips-section__item event-tips-section__mobile"
      >
        <div
          v-if="isLoadingFetchData"
          class="animation-fade-in"
        >
          <div
            v-for="sk in defaultSize"
            :key="sk"
            class="event-tips-section__mobile__item"
            style="display: flex; flex-direction: row; width: 100%; gap: 10px;"
          >
            <div
              class="skeleton-box"
              style="height: 12vh; width: 35vw; border-radius: 5px;"
            />
            <div
              style="display: flex; flex-direction: column; justify-content: space-between; width: 55%;"
            >
              <div
                class="skeleton-box"
                style="height: 2vh; width: 100%;"
              />
              <div
                class="skeleton-box"
                style="height: 2vh; width: 100%;"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <router-link
            v-for="(val, index) in listDataMobile"
            :key="'event-tips-'+ index"
            class="event-tips-section__mobile__item"
            :to="`/info-${val.article_info.toLowerCase()}/${val.corporate_action_slug}`"
          >
            <ImageLazy
              :src="val.attachment_corporate_action_sub_photo_url"
              :alt="val.alt_sub_photo"
            />
            <div class="event-tips-section__mobile__item__child">
              <div>{{ val.corporate_action_title }}</div>
              <div>{{ setTime(val.active_date) }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/new-carousel/Carousel';
import mixinMobile from '@/misc/mixinMobile';
import { chunkArray } from '@/components/new-carousel/util';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { TimeMapper } from '@/misc/timeMapper';
import service from '../../new-event-and-tips/app/usecase/index';

export default {
  name: 'EventAndTipsSectionTrack',
  components: { Carousel, ImageLazy },
  mixins: [mixinMobile],
  data() {
    return {
      isLoadingFetchData: false,
      defaultSize: 4,
      carouselName: 'event-tips-section-track',
      listData: [],
      listDataMobile: [],
      isEmpty: false,
    };
  },
  computed: {
    listChunk() {
      if (this.isMobileSize) {
        return chunkArray(this.listData, 1);
      }
      return chunkArray(this.listData, 4);
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    setTime(date) {
      return TimeMapper.SetFormatDate(date, true, [' ', ' ', '-', ':'], false, false, false);
    },
    async fetchData() {
      this.isLoadingFetchData = true;
      const data = await service.getAllProgram(1, 16, '', 'SELLER');
      if (!data.err) {
        setTimeout(() => {
          this.listData = data.data.data.slice(0, 16);
          this.listDataMobile = data.data.data.slice(0, 4);
          this.isLoadingFetchData = false;
          if (this.listData < 1 && this.listDataMobile < 1) this.isEmpty = true;
        }, 1000);
      } else {
        this.isLoadingFetchData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style";
@import "@/assets/css/animation";

@mixin typography(
    $size,
    $color,
    $line-height,
    $font-weight
  ) {
  font-size: $size;
  color: $color;
  line-height: $line-height;
  font-weight: $font-weight;
}

#base-event-tips {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 1210px;
  padding-bottom: 4rem;

  @media (max-width: 1280px) {
    max-width: unset;
    padding: 0 padding-x(1.5);
  }

  @include for-size(mobile) {
    display: block;
    padding: 0 5% 1rem;
    margin: unset;
    max-width: unset;
  }

  @include for-size(tablet-portrait) {
    height: auto;
    margin: unset;
    max-width: unset;
    padding: 0 padding-x(1);
  }
}

%img {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @include for-size(mobile) {
    border-radius: 5px;
  }
}

.event-tips-section {
  display: flex;
  flex-direction: column;
  padding:0 padding-x(1);
  min-width: 420px;

  @include for-size(mobile) {
    padding: 0 10px;
    min-width: unset;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $color-indigo-dark;
    margin: auto auto auto 0;

    @include for-size(mobile) {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  &__others {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $color-base;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;

      @include for-size(mobile) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    @include for-size(mobile) {
      padding: 0;
      margin-bottom: 10px;
    }
  }

  &__mobile {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      padding: 10px 0 2px 0;
      flex-direction: row;
      border-top: solid 1px #EBECF0;
      border-bottom: solid 1px #EBECF0;
      width: 100%;

      > div {
        &::v-deep img {
          width: 55vw;
          height: 21vw;
          object-fit: cover;
          @extend %img;
        }
      }

      &__child {
        display: flex;
        padding-left: 1rem;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        > div {
          &:first-of-type {
            font-weight: 600;
            font-size: 12px;
            line-height: 17px;
            color: $color-gray-shades-6;

            @include for-size(tablet-portrait) {
              font-size: 14px;
            }
          }

          &:last-of-type {
            font-size: 12px;
            line-height: 17px;
            color: $color-gray-shade;
            padding-bottom: 6px;

            @include for-size(tablet-portrait) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.event-tips-section-carousel__wrapper-item {
  &__child {
    &__image {
      max-width: 280px;
      width: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 5px;
      text-align: left;

      > div:first-child {
        width: 100%;
        margin-bottom: 5px;

        &::v-deep img {
          object-fit: cover;
          @extend %img;
        }
      }

      &__desc {
        width: 100%;
        border-top: 1px solid $color-white-dark;
        padding-top: 10px;
        padding-bottom: 5px;
        @include typography(16px, $color-gray-shades-6, 24px, 600);
      }

      &__date {
        @include typography(12px, $color-gray-shade, 18px, 500);
      }
    }
  }
}

.slide-item {
  margin: 0 8px;
}

.slide-item:first-of-type {
  margin-left: 0;
}

.slide-item:last-of-type {
  margin-right: 0;
}

.slide-number {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}

.slide-number:first-of-type {
  margin-left: 0;
}

.slide-number:last-of-type {
  margin-right: 0;
}

::v-deep .body-slider {
  align-items: unset !important;
}
</style>
