<template>
  <div>
    <div v-if="!isFromTokopedia">
      <card-result
        v-for="(history, index) in histories"
        :key="'history-' +index "
        :title="TimeMapper.SetFormatDate(history.status_date, true, [' ',' ','-',':'] , true , false, true)+' WIB'"
        :desc="history.description"
        :img="sourceImage(index, history.status)"
        :show-border="index !== histories.length - 1"
        :is-b-k-d="history.status === 'BKD'"
        :is-p-o-d="history.status === 'POD'"
        :is-d-e-x="history.status === 'DEX'"
        :is-r-t-s-h-q="history.status === 'RTSHQ'"
        :attachment="history.attachment"
      />
    </div>
    <div
      v-else
      class="tokopedia-wrapper"
      :style="isMultiTracking && !isMobileSize ? 'width: 37.5vw;' : ''"
    >
      <p>
        Pengiriman ini menggunakan layanan
        <br v-if="isMultiTracking"> Kurir Rekomendasi dari <br v-if="isMultiTracking">
        <img
          v-if="isMultiTracking"
          :src="'/images/tracking/track_result_tokopedia.svg'"
          alt="lionparcel tokopedia.svg"
          class="mt-1"
        >
        <span
          v-if="!isMultiTracking"
          class="tokopedia-logo"
        />
        <br> Silahkan lacak pesanan Anda di website/aplikasi <br
          v-if="isMultiTracking"
        >
        Tokopedia.
      </p>
      <div
        style="display: flex; justify-content: center"
        class="mt-2"
      >
        <Button
          text="Cek Pesanan"
          type-class="green"
          @click="$emit('event-tokped')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {STATUS} from "../app/enum/status.js";
import {STATUS as NEWSTATUS} from '../app/enum/new-status';
import mixinMobile from "../../../misc/mixinMobile.js";
import Button from "../../../components/new-button/Button";
import CardResult from "./Card"
import { TimeMapper } from "../../../misc/timeMapper.js";

export default {
  name: "ResultTrackInfo",
  components: {Button, CardResult},
  mixins: [mixinMobile],
  props: {
    dataHistories: {
      type: Array,
      required: true
    },
    dataAttachment: {
      type: Array,
      default: () => []
    },
    isFromTokopedia: {
      type: Boolean,
      default: false,
    },
    isShowModalDownload: { // if POD/Status Sent AND more than 20 days
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      STATUS,
      TimeMapper,
      widthBorder: {
        width: "100%"
      }
    }
  },
  computed: {
    histories() {
      return this.dataHistories
    },
    attachment() {
      return this.dataAttachment
    },
    isMultiTracking() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const q = urlSearchParams.get("q")
      if (this.isMobileSize) {
        return true
      }
      if (!q || q === "") {
        return false
      }
      return q.split(",").length > 1
    }
  },
  methods: {
    /**
     *
     * @param index {number}
     * @param historyEnum {STATUS}
     * @return {string}
     */
    sourceImage(index, historyEnum) {
      if (!NEWSTATUS[historyEnum]) {
        return index === 0 ? "enable_default.svg" : "disable_default.svg"
      }
      return index === 0 ? "enable_" + NEWSTATUS[historyEnum].src + ".svg" : "disable_" + NEWSTATUS[historyEnum].src + ".svg"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/spacing.scss";
@import "../../../assets/css/style.scss";
@import "../../../assets/css/flexbox";

.tokopedia-wrapper {
  text-align: center;
  border: 1px dashed #D4D5D8;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  padding: 15px 13px;
  width: 44vw;
  @include for-size(mobile) {
    width: auto;
  }

  .tokopedia-logo {
    &::before {
      content: url("../../../assets/tracking/track_result_tokopedia.svg");
      position: relative;
      top: 9px;
      margin-left: 4px;
    }
  }

  .link-tokped {
    text-align: center;
    text-decoration-line: underline;
    font-weight: 600;
    color: #42B549;
  }
}

.d-f {
  display: flex;
}

.d-f-col {
  display: flex;
  flex-direction: column;
}
</style>
